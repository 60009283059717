import * as React from "react";
import { IFeatureDetail } from "@types";
import FeatureOnlineBookingCards from "../../components/FeaturesOnlineBookingCards/FeaturesOnlineBookingCards";

const FeaturesOnlineBookingCardsContainer: React.FC<IFeatureDetail.FeatureDetailsCards> =
  ({ FeaturesDetailsCards: { featuresDetailsSections } }) => {
    const Cards = featuresDetailsSections.map((card: any, index: number) => {
      return (
        <FeatureOnlineBookingCards
          list={card.list}
          title={card.title}
          index={index}
          key={index}
        />
      );
    });

    return <>{Cards}</>;
  };

export default FeaturesOnlineBookingCardsContainer;
