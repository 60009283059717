import * as React from "react";
import { graphql, PageProps } from "gatsby";
import { IFeatureDetail } from "@types";

import TestimonialSliderPContainer from "../../containers/TestimonialSliderPContainer/TestimonialSliderPContainer";
import FeatureDetailBanner from "../../components/Banners/featureDetailBanner/featureDetailBanner";
import FeaturesOnlineBookingCardsContainer from "../../containers/FeaturesOnlineBookingCardsContainer/FeaturesOnlineBookingCardsContainer";
import FeaturesOnlineBookingGetStartedContainer from "../../containers/FeaturesOnlineBookingGetStartedContainer/FeaturesOnlineBookingGetStartedContainer";
import SEO from "gatsby-plugin-wpgraphql-seo";
import LayoutWithLargeFooterCta from "../../layouts/LayoutWithLargeFooterCta";
import { deIndexSeo } from "../../utils/deIndexSeo";

const FeatureDetailPage: React.FC<
  PageProps<IFeatureDetail.FeatureDetailsProps>
> = ({ data, location }) => {
  const { wpPage } = data;
  const {
    template: {
      heroP,
      heroWithVideoP,
      testimonialSliderP,
      featuresDetailsP,
      textIconsP,
    },
  } = wpPage;
  return (
    <LayoutWithLargeFooterCta pathname={location.pathname}>
      {wpPage.seo && (
        <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
      )}
      <FeatureDetailBanner
        FeatureDetailBanner={heroP}
        FeatureDetailBannerVideo={heroWithVideoP}
      />
      <FeaturesOnlineBookingCardsContainer
        FeaturesDetailsCards={featuresDetailsP}
      />
      <FeaturesOnlineBookingGetStartedContainer
        FeaturesGetstarted={textIconsP}
      />
      <TestimonialSliderPContainer
        props={testimonialSliderP}
        hasCustomerSatisfaction={true}
      />
    </LayoutWithLargeFooterCta>
  );
};

export const query = graphql`
  query FeaturesTemplateQuery2($id: String!) {
    wpPage(id: { eq: $id }) {
      ...FragmentFeaturesTemplateQuery2
    }
  }
`;

export default FeatureDetailPage;
