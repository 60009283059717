import * as React from "react";
import { IFeatureDetail } from "@types";

import OnlineBookingCards from "./FeatureCard/OnlineBookingCards";
import styles from "./FeaturesOnlineBookingCards.module.scss";

const FeaturesOnlineBookingCards: React.FC<IFeatureDetail.FeaturesOnlineBookingCard> =
  ({ list, title, index }) => {
    const Card = list.map((card: any, index: number) => {
      return (
        <OnlineBookingCards
          cardLength={list.length}
          card={card}
          index={index}
          key={index}
        />
      );
    });
    return (
      <section
        data-testid="section:FeaturesOnlineBookingCards"
        className={`pad-top--large ${index % 2 === 0 && "pad-bottom--large "} ${
          index % 2 === 0 && "grey--faint-bg"
        }`}
      >
        <h2 className={`${styles.CardsTitle} text-alignment full-width`}>
          {title}
        </h2>
        {Card}
      </section>
    );
  };

export default FeaturesOnlineBookingCards;
