import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import BlueLinkWithArrow from "../../Buttons/BlueLinkWithArrow/BlueLinkWithArrow";

import styles from "./OnlineBookingCards.module.scss";

type OnlineBookingCardsProps = {
  listCta: string;
  listCtaText: string;
  listDykBadge: string;
  listDykData: string;
  listDykTitle: string;
  listImage;
  Image;
  listSubcopy: string;
  listTitle: string;
};

const OnlineBookingCards: React.FC<{
  card: OnlineBookingCardsProps;
  cardLength: number;
  index: number;
}> = ({
  card: {
    listCta,
    listCtaText,
    listDykBadge,
    listDykData,
    listDykTitle,
    listImage,
    listSubcopy,
    listTitle,
  },
  cardLength,
  index,
}) => {
  const image = getImage(listImage?.localFile);
  const lastCard = cardLength - 1 === index && !listDykBadge;
  return (
    <>
      <div
        data-testid="div:OnlineBookingCards"
        style={{ paddingBottom: lastCard ? 0 : "80px" }}
        className={`full-width ${styles.featureDetailGrid} ${
          index % 2 !== 0 && styles.flipped
        } `}
      >
        <div className={styles.featureCopy}>
          <h2>{listTitle}</h2>

          <p>{listSubcopy} </p>
          <p>
            <BlueLinkWithArrow link={listCta} linkCopy={listCtaText} />
          </p>
        </div>
        <div className={styles.featureImage}>
          <GatsbyImage image={image} alt={listTitle || ""} />
        </div>
      </div>

      {listDykBadge && (
        <div
          data-testid="div:listDykBadge:OnlineBookingCards"
          className="pad-bottom--intermediate"
        >
          <div
            className={`pos--relative  primary--white-bg full-width ${styles.didYouKnowContainer}`}
          >
            <p className={styles.didYouKnowContainerTitle}>{listDykBadge}</p>
            <h2 dangerouslySetInnerHTML={{ __html: listDykTitle }} />
            <div className="data-courtesy__module">
              <p className="italics mini primary--grey-text">{listDykData}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OnlineBookingCards;
