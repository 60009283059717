import * as React from "react";
import WistiaVideos from "../../components/Videos/WistiaVideos/WistiaVideos";
import { TextIconsPType } from "../../assets/Types/AcfGroupTypes/feature-detail.type";

import styles from "./FeaturesOnlineBookingGetStartedContainer.module.scss";

const FeaturesOnlineBookingGetStartedContainer: React.FC<{
  FeaturesGetstarted: TextIconsPType;
}> = ({ FeaturesGetstarted }) => {
  const { subCopy, title, wistiaId, moduleItems } = FeaturesGetstarted;
  return (
    <div className="grey--faint-bg">
      <section
        className={`pad-top--large pad-bottom--large full-width pos--relative ${styles.getStartedContainer}`}
      >
        <h2>{title}</h2>

        <p
          className={styles.getStartedContainerSubtitle}
          dangerouslySetInnerHTML={{ __html: subCopy }}
        />

        <div className={styles.iconContainer}>
          {moduleItems.length &&
            moduleItems.map((item, id) => (
              <div key={`text-icon-${id}`}>
                <img
                  src={item.icon.localFile.publicURL}
                  alt={item.icon.altText}
                />

                <h3 dangerouslySetInnerHTML={{ __html: item.title }} />
              </div>
            ))}
        </div>
        <hr />
      </section>
      <section className="full-width pad-bottom--large">
        <WistiaVideos videoId={wistiaId} />
      </section>
    </div>
  );
};

export default FeaturesOnlineBookingGetStartedContainer;
