import * as React from "react";
import HomeVideo from "../../components/Videos/HomeVideo/HomeVideo";
import { HeroWithVideoPType } from "../../assets/Types/AcfGroupTypes";

import styles from "./HomePageVideoContainer.module.scss";

type HomePageVideoContainerProps = {
  props: HeroWithVideoPType;
  hasTitle?: boolean;
  heroTitle?: string;
  style?: React.CSSProperties;
  className?: string;
};

const HomePageVideoContainer: React.FC<HomePageVideoContainerProps> = ({
  props: { videoTitle, videoBackgroundImage, videoId, videoSubcopy },
  hasTitle = false,
  heroTitle = "",
  style,
  className = "",
}) => (
  <section
    className={`${styles.container} full-width ${className} pad-bottom--large`}
    style={style}
  >
    {hasTitle && <h2 className={`${styles.heroTitle}`}>{heroTitle}</h2>}
    <HomeVideo videoData={{ videoBackgroundImage, videoId }} />
    {videoTitle && (
      <p className={styles.label}>
        <strong>{videoTitle} </strong>
        <label dangerouslySetInnerHTML={{ __html: videoSubcopy }}></label>
      </p>
    )}
  </section>
);

export default HomePageVideoContainer;
