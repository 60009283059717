import * as React from "react";
import loadVideo from "../../../assets/util-scripts/load-video";
import VideoButton from "../../Buttons/VideoButton/VideoButton";
import { VideoProps } from "../../../assets/Types/VideoProps.type";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import styles from "../Video.module.scss";

const HomeVideo: React.FC<{ videoData: VideoProps }> = ({
  videoData: {
    videoBackgroundImage: { altText, localFile },
    videoId,
  },
}) => {
  const hasVideo = videoId?.length > 0;

  // @ts-ignore
  const image = getImage(localFile);

  const handleClick = () => {
    if (hasVideo) {
      loadVideo(videoId);
    }
  };

  return (
    <div
      data-testid="div:HomeVideo"
      onClick={handleClick}
      className={`${styles.heroVideoContainer} ${
        hasVideo ? styles.hasVideo : styles.noVideo
      }`}
    >
      <GatsbyImage image={image} alt={altText || ""} />
      {hasVideo ? (
        <VideoButton
          buttonColor={"primary--red-bg"}
          iconWhite={true}
          isClass={false}
          videoID={videoId}
          isDemo
        />
      ) : null}
    </div>
  );
};

export default HomeVideo;
