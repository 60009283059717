import * as React from "react";
import { IFeatureDetail } from "@types";

import DownArrow from "../../SVGIcons/DownArrow/DownArrow";

import styles from "./featureDetailBanner.module.scss";

import HomePageVideoContainer from "../../../containers/HomePageVideoContainer/HomePageVideoContainer";

const FeatureDetailBanner: React.FC<IFeatureDetail.FeatureDetailBannerProps> =
  ({
    FeatureDetailBanner: { heroTitle, heroSubcopy },
    FeatureDetailBannerVideo,
  }) => {
    return (
      <>
        <section className="pad-top--large-sans-header text-alignment full-width">
          <div className={styles.hero}>
            <div>
              <h1 data-testid="H1:title">{heroTitle}</h1>
            </div>

            <div>
              <p>{heroSubcopy}</p>
            </div>
          </div>
        </section>
        <div className="pos--relative">
          <HomePageVideoContainer
            className={`pad-bottom--large ${styles.homePageVideo} ${styles.bannerVideoContainer}`}
            props={FeatureDetailBannerVideo}
          />
          <div className={styles.whyImportantCard}>
            <DownArrow cls={styles.icon} />
            Why it&apos;s important
          </div>
        </div>
      </>
    );
  };

export default FeatureDetailBanner;
